<template>
  <div>
    <div style="padding: 5px; text-align: right;">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="openInWindow()"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </template>
        <span>{{$t('tooltips.OPEN_IN_NEW_WINDOW')}}</span>
      </v-tooltip>
    </div>

    <div ref="singleCaseViewComponent" />
  </div>
</template>

<script>
import vueComposer from '@/services/vueComposer'

export default {
  name: 'singleCaseView',
  props: {
    singleCaseView: Object,
    dataset: Object
  },
  data() {
    return {
      
    }
  },
  methods: {
    async createSingleCaseViewComponent() {
      this.destroySingleCaseViewComponent()

      let singleCaseView = this.singleCaseView

      if (!singleCaseView || !singleCaseView.component) {
        return
      }

      this.singleCaseViewVueInstance = vueComposer.createVueInstance(
        singleCaseView.component.template,
        this.dataset,
        singleCaseView.component.options
      )

      let singleCaseViewComponentElement = this.$refs['singleCaseViewComponent']
      
      singleCaseViewComponentElement.appendChild(this.singleCaseViewVueInstance.$mount().$el)
    },

    destroySingleCaseViewComponent() {
      if (this.singleCaseViewVueInstance) {
        let singleCaseViewComponentElement = this.$refs['singleCaseViewComponent']
        singleCaseViewComponentElement.removeChild(singleCaseViewComponentElement.firstChild)
        this.singleCaseViewVueInstance.$destroy()
        this.singleCaseViewVueInstance = null
      }
    },

    openInWindow() {
      this.openHtmlInWindow(this.$refs['singleCaseViewComponent'].innerHTML)
    },

    openHtmlInWindow(html) {
      // If the details window is open, close it, because otherwise the html will get appended to the existing html
      let htmlWindow = this.htmlWindow
      if (htmlWindow) {
        htmlWindow.close()
      }
      let windowWidth = 800
      let windowHeight = 600
      // Determine center position of popup. This should even work with dual screen configurations
      let dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left
      let dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top
      let containerWidth = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
      let containerHeight = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height
      let left = (containerWidth / 2 - windowWidth / 2) + dualScreenLeft
      let top = (containerHeight / 2 - windowHeight / 2) + dualScreenTop
      // Open blank window. Note that using "about:blank" as the url sometimes prevents the html from showing correctly.
      htmlWindow = window.open('', "_resultDetail", 'width=' + windowWidth + ', height=' + windowHeight + ', top=' + top + ', left=' + left + '')
      // Create and set window content
      htmlWindow.document.write(html)
      htmlWindow.document.body.style.cssText = "font-family: Roboto; font-size: 14px; line-height: 1.2; padding: 8px 16px;"
      htmlWindow.document.title = "Result Detail"
      // Put focus on details Window
      if (htmlWindow.focus) {
        htmlWindow.focus()
      }
      // Remember details window
      this.htmlWindow = htmlWindow
    }
  },  
  mounted() {
    this.createSingleCaseViewComponent()
  },
  beforeDestroy () {
    this.destroySingleCaseViewComponent()
  },
  watch: {    
    singleCaseView() {
      this.createSingleCaseViewComponent()
    }, 
    dataset() {
      this.createSingleCaseViewComponent()
    }
  }
}
</script>

<style lang="scss">
</style>