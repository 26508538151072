import { render, staticRenderFns } from "./IFrame.vue?vue&type=template&id=7720d945&scoped=true"
import script from "./IFrame.vue?vue&type=script&lang=js"
export * from "./IFrame.vue?vue&type=script&lang=js"
import style0 from "./IFrame.vue?vue&type=style&index=0&id=7720d945&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7720d945",
  null
  
)

export default component.exports