<template>
<div v-if="ready" id="tq-analysis" v-bind:class="analysisSidebarClass" style="height: 100%; display: flex; flex-direction: column; width: 100%;">
  <md-dialog :md-active.sync="showShortUrlDialog">
    <md-dialog-title>{{ $t('texts.ANALYSIS_LINK') }}</md-dialog-title>
    <md-dialog-content style="overflow-y:hidden;">
      <div style="display: flex; align-items: center;">
        <div style="flex: 1 0 0; background: #f5f5f5; border: 1px solid #ddd; padding: 0 10px;">
          <div style="font-size: 16px; overflow: hidden; height: 0;">
            <!-- not visible: only used to stretch dialog -->
            {{ shortUrl }}
          </div>
          <md-field>  
            <md-input @click="clickShortUrl" v-model="shortUrl" readonly></md-input>
          </md-field>
        </div>
        <div style="flex: 0 0 0;">
          <md-button @click="copyUrlToClipboard()" class="md-icon-button"><md-icon>file_copy</md-icon><md-tooltip>{{ $t('texts.COPY_LINK_TO_CLIPBOARD') }}</md-tooltip></md-button>
        </div>
      </div>
    </md-dialog-content>
    
    <md-dialog-actions>
      <md-button @click="showShortUrlDialog = false">{{ $t('actions.close') }}</md-button>
    </md-dialog-actions>
  </md-dialog>

  <email-dialog v-model="isShowEmailDialog" :selDatasets="selDatasets" :datasetsElement="datasetsElement"></email-dialog>
  <md-dialog-alert :md-active.sync="isShowNoSelRowsAlert" md-title="No rows selected" md-content="Please select rows first."/>
  
  <div style="flex: 1 0 0; display: flex; flex-direction: row-reverse;">
    <div style="flex: 1 1 auto; width: 100%; display: flex; flex-direction: column; overflow: hidden; border-top: 1px solid #ddd; position: relative;">
      <tq-message :display="$store.state.filterValuesChanged || ($store.state.analysisSettingsTabulationDirty && (analysisView === 'tabulation' || analysisView === 'chart'))" :text="messageText" :button-text="messageButtonText" @click="applyMessage"></tq-message>
      <div style="flex: 0 0 auto; display: flex; align-items: center;">
        <div style="flex: 1 0 auto; display: flex; align-items: center; padding: 0 15px 0 0;">
          <md-tabs :md-active-tab="analysisView + '_tab'">
            <md-tab id="datasets_tab" :md-label="$t('labels.datasets')" @click="setAnalysisView('datasets')"></md-tab>
            <md-tab id="tabulation_tab" :md-label="$t('labels.tabulation')" @click="setAnalysisView('tabulation')"></md-tab>     
            <md-tab v-if="showCharts" id="chart_tab" :md-label="$t('labels.charts')" @click="setAnalysisView('chart')"></md-tab>     
          </md-tabs>
          <div style="flex: 1 0 0;"></div>
          <span v-if="false" style="background: #fff; border-radius: 30px;">
            <md-button  @click="historyBack" class="menu-button md-icon-button" :disabled="$store.state.history.cursor === -1"><md-icon>arrow_back</md-icon><md-tooltip>{{ $t('tooltips.NAVIGATE_BACK') }}</md-tooltip></md-button>
            <md-button @click="historyForward" class="menu-button md-icon-button" :disabled="$store.state.history.length <= ($store.state.history.cursor + 2)"><md-icon>arrow_forward</md-icon><md-tooltip>{{ $t('tooltips.NAVIGATE_FORWARD') }}</md-tooltip></md-button>
          </span>
          <span class="toolbar-buttons">
            <md-button @click="exportData" class="menu-button"><md-icon>file_download</md-icon>{{ $t('texts.EXPORT') }}</md-button>
            <md-button @click="createLink" class="menu-button"><md-icon>link</md-icon>{{ $t('texts.CREATE_LINK') }}</md-button>
            <md-button v-if="emailStatus != null" @click="showEmailDialog" class="menu-button" :disabled="emailStatus !== 'enabled'"><md-icon>mail</md-icon>{{ $t('texts.SEND_EMAIL') }}</md-button>
            <md-button @click="refresh({noCache: true})" class="menu-button"><md-icon>refresh</md-icon>{{ $t('texts.REFRESH') }}</md-button>
          </span>
          <md-menu class="toolbar-menu" md-direction="bottom-start">
            <md-button md-menu-trigger class="md-icon-button"><md-icon>more_vert</md-icon></md-button>
            <md-menu-content>
              <md-menu-item @click="exportData">
                <md-icon>file_download</md-icon>
                <span class="md-list-item-text">{{ $t('texts.EXPORT') }}</span>
              </md-menu-item>
              <md-menu-item @click="createLink">
                <md-icon>link</md-icon>
                <span class="md-list-item-text">{{ $t('texts.CREATE_LINK') }}</span>
              </md-menu-item>
              <md-menu-item v-if="emailStatus != null" @click="showEmailDialog" :disabled="emailStatus != 'enabled'">
                <md-icon>mail</md-icon>
                <span class="md-list-item-text">{{ $t('texts.SEND_EMAIL') }}</span>
              </md-menu-item>
              <md-menu-item @click="refresh({noCache: true})">
                <md-icon>refresh</md-icon>
                <span class="md-list-item-text">{{ $t('texts.REFRESH') }}</span>
              </md-menu-item>
            </md-menu-content>
          </md-menu> 
        </div>
      </div>
      <template v-if="showViews">
        <div style="flex: 1 0 0;" v-show="analysisView === 'datasets'">
          <results-table ref="datasets" v-bind:enabled="analysisView === 'datasets'" @on-change="onResultsTableChange" />
        </div>

        <div style="flex: 1 0 0;" v-show="analysisView === 'tabulation'">
          <pivot-table ref="tabulation" v-bind:enabled="analysisView === 'tabulation'"/>
        </div>
        <div style="flex: 1 0 0;" v-show="analysisView === 'chart'">
          <data-chart ref="chart" v-bind:enabled="analysisView === 'chart'"/>
        </div>
      </template>
    </div>
    <div style="flex: 0 0 8px; border-right: 1px solid #ddd; border-top: 1px solid #ddd; position: relative;">
    </div>
    <div class="drawer-handle-container">
      <div class="drawer-handle md-elevation-4">
        <div class="drawer-handle-inner" @click="toggleDrawer"><md-icon>keyboard_arrow_right</md-icon></div>
        <md-tooltip>{{ showDrawer ? $t('tooltips.CLOSE_SIDEBAR') : $t('tooltips.OPEN_SIDEBAR')}}</md-tooltip>
      </div>
    </div>
    <div class="content-left md-elevation-4" v-bind:style="contentLeftStyle" ref="contentLeft">
      <div class="content-left-wrapped" v-bind:style="contentLeftWrappedStyle">
        <div class="content-left-tools">
          
          <div class="content-left-tool" v-bind:class="{ 'content-left-tool-active': toolActive('views') }"  @click="clickViews">
            <md-tooltip md-direction="right">{{ $t('tooltips.SECTIONS') }}</md-tooltip>
            <md-button class="content-left-tools-views">
              <md-icon class="content-left-icons-views">dynamic_feed</md-icon>
            </md-button>
          </div>
          <div class="content-left-tool" v-bind:class="{ 'content-left-tool-active': toolActive('filters') }" @click="clickFilters">
            <md-tooltip md-direction="right">{{ $t('tooltips.FILTER') }}</md-tooltip>
            <md-button class="content-left-tools-filters">
              <md-icon class="content-left-icons-filters">filter_list</md-icon>
            </md-button>
          </div>
          <div class="content-left-tool" v-bind:class="{ 'content-left-tool-active': toolActive('settings') }" @click="clickSettings">
              <md-tooltip md-direction="right">{{ $t('tooltips.SETTINGS') }}</md-tooltip>
            <md-button class="content-left-tools-settings">
              <md-icon class="content-left-icons-settings">settings</md-icon>
            </md-button>
          </div>
          <div class="content-left-tool" v-bind:class="{ 'content-left-tool-active': toolActive('bookmarks') }" @click="clickBookmarks">
            <md-tooltip md-direction="right">{{ $t('tooltips.BOOKMARKS') }}</md-tooltip>
            <md-button class="content-left-tools-bookmarks">
              <md-icon class="content-left-icons-bookmarks">bookmark</md-icon>
            </md-button>
          </div>
          <div class="content-left-tools-bar"></div>
        </div>
        
        <template v-if="showViews">
          <analysis-views v-show="analysisSidebar==='views'" @changeView="changeView"></analysis-views>
          <analysis-filters v-show="analysisSidebar==='filters'"></analysis-filters>
          <analysis-settings v-show="analysisSidebar==='settings'"></analysis-settings>
        </template>

        <analysis-settings-manager v-show="analysisSidebar==='bookmarks'" @applyAnalysis="applyAnalysis"></analysis-settings-manager>
      </div>
    </div>
  </div>

  <analysis-chips></analysis-chips>
</div>
</template>

<script>
import url from '@/services/url'
import api from '@/services/api'
import error from '@/services/error'
import _ from 'lodash'
import localStorageHelper from '@/services/localStorageHelper'
import ViewModel from '@/models/view'
import analysisSettingsCompletion from '@/services/analysisSettingsCompletion'

import { t } from '@/i18n'

export default {
  label: 'Analysis',
  data: () => ({
    showViews: true,
    showDrawer: true,
    drawerWidth: 350,
    ready: false,
    shortUrl: null,
    showShortUrlDialog: false,
    isShowEmailDialog: false,
    isShowNoSelRowsAlert: false,
    selDatasets: null,
    datasetsElement: null,
    showCharts: false
  }),
  methods: {
    async refresh(options) {
      options = Object.assign({
        updateMeta: true,
        updateResultMap: true,
        updateDatasets: true,
        updateTabulation: true,
        updateChart: true,
      }, options || {})

      let noCache = options.noCache

      if (options.updateMeta) {
        await this.$store.dispatch('getSourceViewSortedResultsMeta', {
          verbatimVar: this.$store.state.analysisSettings.level === 'verbatim' ? this.$store.state.analysisSettings.verbatim_var : null,
          noCache: noCache
        })
      }

      options.updateResultMap && this.$store.commit('setResultMap', {})
      options.updateDatasets && this.$refs.datasets.refresh()
      options.updateTabulation && this.$refs.tabulation.refresh(noCache)
      options.updateChart && this.$refs.chart.refresh(noCache)
    },

    async historyBack() {
      this.$store.dispatch('setLoading', true)

      let history = await this.$store.dispatch('getHistory', {
        direction: 'back'
      })

      let path = history.path
      if (path) {
        window.location = path
      }
    },
    async historyForward() {
      this.$store.dispatch('setLoading', true)

      let history = await this.$store.dispatch('getHistory', {
        direction: 'forward'
      })

      let path = history.path
      if (path) {
        window.location = path
      }
    },
    onResultsTableChange() {
      this.refresh({
        updateResultMap: false,
        updateDatasets: false,
        updateMeta: false
      })
    },
    async applyMessage() {
      let state = this.$store.state
      if (state.filterValuesChanged) {
        await this.$store.dispatch('setQueryFromFilter')
        if (this.analysisView === 'tabulation') {
          this.$refs.tabulation.getData(true, false)
        } else if (this.analysisView === 'chart') {
          this.$refs.chart.getData(true, false)
        } else {
          this.$refs.tabulation.changed = true
        }
        url.updateAnalysisUrl(this.analysisView, true)
      } else if (state.analysisSettingsTabulationDirty) {
        if (this.analysisView === 'tabulation') {
          this.$refs.tabulation.getData(true, false)
        } else if (this.analysisView === 'chart') {
          this.$refs.chart.getData(true, false)
        }
        url.updateAnalysisUrl(this.analysisView, true)
      }
    },
    changeView(view, queryParams) {
      ViewModel.routeTo(view, queryParams)
    },
    async applyAnalysis(viewName, analysisSettings, filters, analysisView) {
      try {
        this.showViews = false

        this.$forceUpdate()

        //let view =  this.$store.state.view

        let viewChanged = this.$store.state.view.name !== viewName
        let view = viewChanged ? await this.$store.dispatch('getSourceView', { viewName: viewName }) : this.$store.state.view

        let analysisSettingsModel = view.analysisSettingsModel
        analysisSettingsCompletion(analysisSettings, analysisSettingsModel)
        this.$store.dispatch('setAnalysisSettings', analysisSettings)

        if (filters) {
          this.$store.dispatch('assignFilterValues', filters)
        }
        await this.$store.dispatch('setQueryFromFilter')

        analysisView = analysisView || this.$store.state.analysisView || 'datasets'
        let viewSettings = ViewModel.getSettings(view)
        this.showCharts = !!viewSettings.charts
        // if charts are not supported, but currently charts are showing, change to tabulation
        if (!this.showCharts && analysisView === 'chart') {
          analysisView = 'tabulation'
        }
        if (analysisView) {
          this.setAnalysisView(analysisView)
        }

        url.updateAnalysisUrl(this.$store.state.analysisView, true)

        await this.$nextTick()
        this.showViews = true
      } catch (e) {
        error.runtimeError(e)
      }
    },
    /*async applySettingsAndFilters(analysisSettings, filters, analysisView) {
      try {
        if (analysisView) {
          this.setAnalysisView(analysisView)
        }

        this.$store.dispatch('setAnalysisSettings', analysisSettings)

        if (filters) {
          this.$store.dispatch('assignFilterValues', filters)
        }
        
        await this.$store.dispatch('setQueryFromFilter')
        
        if (this.analysisView == 'tabulation') {
          this.$refs.tabulation.getData(true, false)
          // Chart relies on tabulation data, ensure it gets rerendered
          this.$store.commit('setAnalysisSettingsChartDirty', true)
        } else if (this.analysisView == 'chart') {
          this.$store.commit('setAnalysisSettingsTabulationDirty', true)
          this.$refs.chart.getData(true, false)
        } else {
          this.$store.commit('setAnalysisSettingsTabulationDirty', true)
          this.$store.commit('setAnalysisSettingsChartDirty', true)
        }
      } catch (e) {
        error.runtimeError(e)
      }
    },*/
    toggleDrawer() {
      this.showDrawer = !this.showDrawer
    },
    setAnalysisView(view) {
      if (view) {
        this.$store.dispatch('setAnalysisView', view)
      }
    },
    setAnalysisSidebar(analysisSidebar) {
      if (analysisSidebar) {
        this.$store.dispatch('setAnalysisSidebar', analysisSidebar)
      }
    },
    updateUrl(replace) {
      url.updateAnalysisUrl(this.analysisView, replace)
    },
    exportData() {
      let viewComponent = this.$refs[this.analysisView]
      if (viewComponent) {
        viewComponent.exportData()
      }
    },
    createLink() {
      api.call('saveLink', {
        linkType: 'ANALYSIS_SETTINGS_LINK',
        data: {
          analysisView: this.analysisView,
          path: this.$route.fullPath
        }
      }, response => {
        let linkId = response.id  
        let protocol = location.protocol
        let port = location.port
        let host = window.location.hostname
        this.shortUrl = protocol + '//' + host + (port && port !== 80 && port !== 443 ? ':' + port : '')  +  '/' + linkId
        this.showShortUrlDialog = true
      })
    },
    showEmailDialog() {
      this.selDatasets = this.$refs.datasets.getSelectedDatasets()
      this.datasetsElement = this.$refs.datasets
      if (this.selDatasets.length === 0) {
        this.isShowNoSelRowsAlert = true
      } else {
        this.isShowEmailDialog = true
      }
    },    
    async copyUrlToClipboard() {
      try {
        await navigator.clipboard.writeText(this.shortUrl)
      } catch(e) {
        error.runtimeError(new Error('Could not copy url'), {
          hideClose: false,
          hideReset: true,
          hideReload: true
        })
      }
    },
    toolActive(analysisSidebar) {
      return this.analysisSidebar === analysisSidebar
    },
    clickShortUrl(event) {
      event.target.select()
    },
    clickViews() {
      this.setAnalysisSidebar('views')
    },
    clickBookmarks() {
      this.setAnalysisSidebar('bookmarks')
    },
    clickSettings() {
      this.setAnalysisSidebar('settings')
    },
    clickFilters() {
      this.setAnalysisSidebar('filters')
    }
  },
  computed: { 
    analysisView() {
      // Currenlty open view or tab (any of 'datasets', 'tabulation', 'chart')
      return this.$store.state.analysisView
    },
    analysisSidebar() {
      return this.$store.state.analysisSidebar
    },
    analysisSidebarClass() {
      return {
        'sidebar-views': this.analysisSidebar === 'views',
        'sidebar-settings': this.analysisSidebar === 'settings',
        'sidebar-filters': this.analysisSidebar === 'filters',
        'sidebar-bookmarks': this.analysisSidebar === 'bookmarks',
        'show-drawer': this.showDrawer
      }
    },
    contentLeftStyle() {
      return {
        flex: '0 0 ' + (this.showDrawer ? this.drawerWidth : 0) + 'px'
      }
    },
    contentLeftWrappedStyle() {
      return {
        width: this.drawerWidth + 'px'
      }
    },
    messageText() {
      let state = this.$store.state
      if (state.filterValuesChanged) {
        if (state.analysisSettingsTabulationDirty && (this.analysisView === 'tabulation' || this.analysisView === 'chart')) {
          return t('texts.CHANGED_FILTERS_AND_SETTINGS')
        } else {
          return t('texts.CHANGED_FILTERS')
        }
      } else if (state.analysisSettingsTabulationDirty) {
        return t('texts.CHANGED_SETTINGS')
      }
    },
    messageButtonText() {
      let state = this.$store.state
      if (state.filterValuesChanged) {
        if (state.analysisSettingsTabulationDirty && (this.analysisView === 'tabulation' || this.analysisView === 'chart')) {
          return t('actions.apply')
        } else {
          return t('labels.apply_filter')
        }
      } else if (state.analysisSettingsTabulationDirty) {
        return t('actions.apply')
      }
    },    
    emailStatus() {
      let isAvailable =  this.$store.state.view != null && this.$store.state.view.settings != null && this.$store.state.view.settings.emails === true
      let isEnabled = (this.analysisView === 'datasets') && isAvailable
      let status = isEnabled ? 'enabled' : isAvailable ? 'available' : null    
      return status
    }
  },
  watch: {
    analysisView() {
      this.updateUrl(true)
    },
    analysisSidebar() {
      this.updateUrl(true)
    } 
  },
  async mounted() {
    try {
      let store = this.$store

      let source = store.state.source
      let view = store.state.view

      if (!source && !view) {
        error.throw(null, {
          clientDebugData: {
            reason: 'analysis needs source and view',
            view: view,
            source: source
          }
        })
      }
      
      let analysisSettings = this.$route.query.analysisSettings
      let filter = this.$route.query.filter
      let analysisView = this.$route.query.analysisView
      let analysisSidebar = this.$route.query.analysisSidebar
      let version = this.$route.query.v && parseInt(this.$route.query.v)
      let lastAnalysis
      let lastAnalysisCache = localStorageHelper.getLastAnalysisCache()// localStorage.getItem(storageName)
      lastAnalysisCache = lastAnalysisCache && JSON.parse(lastAnalysisCache)

      let sourceName = source.name
      let viewName = view.name

      let viewSettings = ViewModel.getSettings(view)
      this.showCharts = viewSettings == null ? false : (viewSettings.charts === true)

      // if charts are not supported, but currently charts are showing, change to tabulation
      if (!this.showCharts && analysisView === 'chart') {
        analysisView = 'tabulation'
      }  

      if (version != null && version !== source.version) {
        delete lastAnalysisCache[sourceName]
        localStorageHelper.setLastAnalysisCache(lastAnalysisCache)
        return this.changeView()
      }

      if (lastAnalysisCache) {
        let lastAnalysisBySourceName = lastAnalysisCache[sourceName]
        if (lastAnalysisBySourceName) {
          if (
            // delete the last analysis settings in localstorage if
            // (1) resetDefault is set (Issue #500)
            this.$route.query.resetDefault ||
            // (2) version has changed
            (source.version && (!lastAnalysisBySourceName.__sourceVersion || source.version > lastAnalysisBySourceName.__sourceVersion))
          ) {
            // Local storage has an old source version
            // delete old cache
            lastAnalysisBySourceName = null
            delete lastAnalysisCache[sourceName]
            localStorageHelper.setLastAnalysisCache(lastAnalysisCache)
          } else {
            lastAnalysis = lastAnalysisBySourceName[viewName]
          }
        }
      }

      if (lastAnalysis) {
        analysisSettings = analysisSettings || lastAnalysis.analysisSettings
        filter = filter || lastAnalysis.filterValues
        analysisView = analysisView || lastAnalysis.analysisView
        analysisSidebar = analysisSidebar || lastAnalysisCache.analysisSidebar
      }
        
      if (analysisSettings) {
        analysisSettings = url.fromAnalysisSettingsUrl(analysisSettings)
        await this.$store.dispatch("initAnalysis", { analysisSettings: analysisSettings })
      } else {
        await this.$store.dispatch("initAnalysis", {})
        analysisSettings = this.$store.state.analysisSettings
      }

      if (filter) {
        filter = _.isString(filter) ? url.fromFilterUrl(filter) : filter

        await this.$store.dispatch('assignFilterValues', filter)
        await this.$store.dispatch('setQueryFromFilter')
      }

      if (analysisView) {
        this.setAnalysisView(analysisView)
      }

      if (analysisSidebar) {
        this.setAnalysisSidebar(analysisSidebar)
      }
      
      this.updateUrl(true)

      this.unwatchStore = this.$store.watch(state => state.analysisSettings, newSettings => {
        //this.updateUrl(true)
      })

      if (!this.$store.state.sortedResultMeta) {
        await this.$store.dispatch('getSourceViewSortedResultsMeta', {
          verbatimVar: analysisSettings.level === 'verbatim' ? analysisSettings.verbatim_var : null
        })
      }
     
      this.ready = true
    } catch(e) {
      error.runtimeError(e)
    }
  },
  beforeDestroy() {
    url.lastQueryUrl = null
    this.unwatchStore &&  this.unwatchStore()
  }
}
</script>

<style lang="scss">
  #tq-analysis {
    $animation-speed: 500ms;


    @media screen and (max-width: 1200px) {
      .toolbar-buttons {
        display: none;
      }
    }

    @media screen and (min-width: 1201px) {
      .toolbar-menu {
         display: none;
      }
    }
   

    .content-left {
      display: flex;
      position: relative;
      flex-direction: column;
      min-width: 0;
      overflow: hidden;
      height: 100%;
      transition: flex $animation-speed;
      background: #fff;
      z-index: 102;
      

      .content-left-tool {
        transition: background 500ms;

        .md-button {
          min-height: 60px;
          min-width: 50px;
          margin: 0;
          border-radius: 0;
        }
        
        .md-icon {
          font-weight: 400;
        }
      }

      /*.content-left-tool-active {
        background: #fff;
        
        .md-button {
          background: #fff !important;
        }

        .md-button:before {
          background: #fff !important;
        }        
      }*/
    }

    .content-left-wrapped {
      flex: 1 0 0;
      display: flex;
      overflow: auto;
    }

    .content-left-tools {
      position: relative;
      flex: 0 0 0;
      display: flex;
      flex-direction: column;
      justify-content: top;
      background: #f5f5f5;
      border-right: 1px solid #ddd;
    }

    .content-left-tools-bar {
      position: absolute;
      top: 0;
      right: 0;
      width: 2px;
      height: 60px;
      background: #cd041e;
      transition: top 300ms;
    }

    .drawer-handle-container {
      position: relative;
      flex: 0 0 0;
      color: #4e5152;
      border-left: 0 solid #777;
      transition: border-width 400ms;
      background: #777;

      .drawer-handle {
        z-index: 101;
        background: #777;
        top: calc(50%);
      }  
    }

    .drawer-handle {
      opacity: 1;
      $drawer-diameter: 40px;
      $drawer-radius: $drawer-diameter / 2;
      position: absolute;
      z-index: 1;
      left: -$drawer-radius;
      height: $drawer-diameter;
      width: $drawer-diameter;
      border-radius: $drawer-radius;
      margin: -$drawer-radius 0 0 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity 1s;
      transition: top 300ms;
      
      .drawer-handle-inner {
        margin: 0 0 0 $drawer-radius;
        width: $drawer-radius;
        position: relative;
        left: -3px;

        i {
          color: #fff;
          transition: transform $animation-speed;
        }
      }
    }

    .menu-button {
      font-size: 13px;
    }
    
    .menu-button .md-icon {
      margin: 0 4px;
    }

    .menu-button .md-button-content {
      display: flex;
      align-items: center;
    }

  }

  #tq-analysis.show-drawer {
    .filters {
      flex: 0 0 300px;
    }

    .drawer-handle-container {
      .drawer-handle {
        opacity: 1;
        i {
          transform: rotate(180deg);
        }
      }
    }
  }

  #tq-analysis:not(.show-drawer) {
    .drawer-handle-container:hover {
      border-left-width: 5px;
    }      
  }

  #tq-analysis.sidebar-views {
    .content-left-icons-views {
      color: $primary;
    }

    .content-left-tools-bar {
      top: 0;
    }
  }

  #tq-analysis.sidebar-filters {
    .content-left-icons-filters {
      color: $primary;
    }

    .content-left-tools-bar {
      top: 60px;
    }
  }

  #tq-analysis.sidebar-bookmarks {
    .content-left-icons-bookmarks {
      color: $primary;
    }

    .content-left-tools-bar {
      top: 180px;
    }
  }

  #tq-analysis.sidebar-settings {
    .content-left-icons-settings {
      color: $primary
    }

    .content-left-tools-bar {
      top: 120px;
    }
  }
  
</style>