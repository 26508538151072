/**
 * Evaluates simple boolean expressions like
 * 
 *  "$view == 'tabulation'"
 *  "$settings.level == 'verbatim'"
 *  "$item.type == 'categorical' or $item.type == 'date' or $item.type == 'number' or $item.type == 'text'"
 *  "$item.type == 'categorical' and not($item.name in $settings.tabulation_columns)"
 *  "$item.type == 'categorical' and ($item.verbatimVar == null or ($settings.level == 'verbatim' and $item.verbatimVar == $settings.verbatim_var))"
 * 
 * Elements beginning with a '$' are scope variables, which will be accessed as fields from the scope. For example
 * '$item' will be accessed as 'scope["item"]' by the parser.
 * 
 * @param boolEx A string containing the expression
 * @param scope An object mapping scope variables to objects
 * @returns {Boolean}
 */

export default function evalExpression(boolEx, scope) {
  let tokens = boolEx ? boolEx.match(/\(|\)|\[|\]|\,|[A-Za-z0-9_$.\-]+|["][^"]*["]|['][^']*[']|[<>=\!]+/g) : [];
  return evalExpressionTokens(tokens, scope);
}


let readValue = function(token, scope) {
  let value = null;
  if (token != null) {
    if (token[0] === '"' || token[0] === '\'') {
      // string
      value = token.substring(1, token.length - 1);
    } else if (token[0] === '$') {
      // scope variable
      let scopeVarTokens = token.substring(1).split('.');
      value = scope;
      while (value != null && scopeVarTokens.length) {
        value = value[scopeVarTokens.shift()];
      }
    } else if (token === 'null') {
      value = null;
    } else if (token === 'true') {
      value = true;
    } else if (token === 'false') {
      value = false;
    } else {
      // other value (for example number)
      value = token;
    }
  }
  return value;
}

let evalExpressionTokens = function(tokens, scope) {
  let combinator;
  let result = true;
  while (tokens.length) {
    let token = tokens.shift();        
    if (token === 'and' || token === 'or') {
      combinator = token;
    } else if (token === ')') {
      // end of sub-expressin
      break;
    } else {
      let subResult;
      let negated = false;  
      while (token === 'not') {
        negated = !negated;
        token = tokens.shift();
      }
      if (token === '(') {
        // evaluate sub-expression
        subResult = evalExpressionTokens(tokens, scope);
      } else {
        // read values and operator
        let val1 = readValue(token, scope);
        let operator = tokens.shift();
        let val2;
        token = tokens.shift();
        if (token === '[') {
          // value 2 is array
          val2 = [];
          token = tokens.shift();
          while (token != null && token !== ']') {
            val2.push(readValue(token, scope));
            token = tokens.shift();
            if (token === ',') {
              token = tokens.shift();
            }
          }
        } else {
          // value 2 is string, number or scope variable
          val2 = readValue(token, scope);
        }
        // compare values using operator
        switch (operator) {
          case '==':
            subResult = val1 == val2;
            break;
          case '!=':
          case '<>':
            subResult = val1 != val2;
            break;
          case 'in':
            // check if val2 contains val1
            if (Array.isArray(val2)) {
              subResult = val2.indexOf(val1) >= 0;
            } else {
              subResult = false;
            }
            break;
            case 'nin':
              // check if val2 does not contain val1
              if (Array.isArray(val2)) {
                subResult = val2.indexOf(val1) === -1;
              } else {
                subResult = true;
              }
              break;
          default:
            subResult = false;
        }
      }
      if (negated) {
        subResult = !subResult;
      }
      if (combinator === 'and') {
        result = result && subResult;
      } else if (combinator === 'or') {
        result = result || subResult;
      } else { // no combinator
        result = subResult;
      }
    }
  }
  return result;
}