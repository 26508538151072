import Vue from 'vue'
import dateFormat from 'dateformat'
import store from '@/store/index'
import _ from 'lodash'

const execEval = options => {
  // disabled because of eval() security vulnerability
  return {}

  // keep code in case options should be used
  //
  // let componentOptions = {}
  // if (options) {
  //   eval(`componentOptions = ${options}`)
  // }
  //
  // return componentOptions*/
}

export default {
  createVueInstance(template, dataset, options, errorHandler) {
    errorHandler = Object.assign({
      warning: () => {}, 
      error: () => {}
    }, errorHandler || {})

    let componentOptions = options ? execEval(options) : {}

    let vueOptions = {
      template: template || '',
      data: () => {
        return {
          dataset: dataset || {},
          dateFormat: dateFormat
        }
      },
      methods: {
        get(path) {
          return _.get(dataset, path)
        },
        getDate() {
          return '<< getDate() >>'
        },
        surveyLink() {
          return '<< surveyLink() >>'
        },
        surveyUrl() {
          return '<< surveyUrl() >>'
        }
      }
    }

    if (componentOptions.data) {
      let data = Object.assign(vueOptions.data(), componentOptions.data())
      
      vueOptions.data = () => data

      delete componentOptions.data
    }

    if (componentOptions.methods) {
      Object.assign(vueOptions.methods, componentOptions.methods)

      delete componentOptions.methods
    }

    Object.assign(vueOptions, componentOptions)

    let vueInstance = new Vue(vueOptions)

    vueInstance.__ERROR_HANDLER = errorHandler

    return vueInstance
  },

  async open(options) {
    options = Object.assign({
      surveyDefinition: null
    }, options || {})

    let dialogObj

    let data = await new Promise(resolve => {
      dialogObj = {
        options: options,
        resolve: resolve
      }

      let surveyCreatorDialogs = store.state.surveyCreatorDialogs
      surveyCreatorDialogs.push(dialogObj)

      store.dispatch('setSurveyCreatorDialogs', surveyCreatorDialogs)
    })

    await store.dispatch('setSurveyCreatorDialogs', store.state.surveyCreatorDialogs.filter(dialog => dialog !== dialogObj))

    return data
  }
}